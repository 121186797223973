<template>
  <v-app>
    <nav>
      <v-navigation-drawer
        v-model="showNav"
        absolute
        temporary
        class="primary"
        :width="320"
      >
        <div class="d-flex justify-center white">
          <v-img
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            src="@/assets/main-logo.png"
            transition="scale-transition"
            width="128"
          />
        </div>

        <v-divider></v-divider>

        <v-list dense>
          <v-list-item key="Home" link to="/">
            <v-list-item-icon>
              <v-icon class="secondary--text">mdi-view-dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="secondary--text"
                >Home</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item key="Member" link to="/member">
            <v-list-item-icon>
              <v-icon class="secondary--text">mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="secondary--text"
                >Member</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <!-- <v-list-item key="MemberBon" link to="/member-bon">
            <v-list-item-icon>
              <v-icon class="secondary--text">mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="secondary--text"
                >Member Bon</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item> -->

          <v-list-item>
            <v-expansion-panels focusable>
              <v-expansion-panel v-show="showPanel(pnlPenukaranRole)">
                <v-expansion-panel-header
                  class="secondary--text"
                  style="background: #31d8a7; margin: 0px; padding: 0px"
                >
                  <div>
                    <v-icon>mdi-swap-horizontal-bold</v-icon>
                    <span
                      style="
                        padding-left: 32px;
                        font-weight: 450;
                        font-size: 13px;
                      "
                      >Penukaran & Pengambilan</span
                    >
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  elevation="0"
                  class="secondary--text"
                  style="background: #31d8a7; margin: 0px; padding: 0px"
                >
                  <v-list>
                    <v-list-item
                      v-for="item in itemsPenukaran"
                      :key="item.title"
                      link
                      :to="item.link"
                    >
                      <v-list-item-icon>
                        <v-icon class="secondary--text">{{ item.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="secondary--text">{{
                          item.title
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel v-show="showPanel(pnlPenukaranRole)">
                <v-expansion-panel-header
                  class="secondary--text"
                  style="background: #31d8a7; margin: 0px; padding: 0px"
                >
                  <div>
                    <v-icon>mdi-file-document-multiple</v-icon>
                    <span
                      style="
                        padding-left: 32px;
                        font-weight: 450;
                        font-size: 13px;
                      "
                      >Laporan</span
                    >
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  elevation="0"
                  class="secondary--text"
                  style="background: #31d8a7; margin: 0px; padding: 0px"
                >
                  <v-list>
                    <v-list-item
                      v-for="item in itemsLaporan"
                      :key="item.title"
                      link
                      :to="item.link"
                    >
                      <v-list-item-icon>
                        <v-icon class="secondary--text">{{ item.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="secondary--text">{{
                          item.title
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel v-show="showPanel(pnlPromoRole)">
                <v-expansion-panel-header
                  class="secondary--text"
                  style="background: #31d8a7; margin: 0px; padding: 0px"
                >
                  <div>
                    <v-icon>mdi-gift</v-icon>
                    <span
                      style="
                        padding-left: 32px;
                        font-weight: 450;
                        font-size: 13px;
                      "
                      >Promo & Notifikasi</span
                    >
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  elevation="0"
                  class="secondary--text"
                  style="background: #31d8a7; margin: 0px; padding: 0px"
                >
                  <v-list>
                    <v-list-item
                      v-for="item in itemsPromo"
                      :key="item.title"
                      link
                      :to="item.link"
                    >
                      <v-list-item-icon>
                        <v-icon class="secondary--text">{{ item.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="secondary--text">{{
                          item.title
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel v-show="showPanel(pnlPoinRole)">
                <v-expansion-panel-header
                  class="secondary--text"
                  style="background: #31d8a7; margin: 0px; padding: 0px"
                >
                  <div>
                    <v-icon>mdi-bitcoin</v-icon>
                    <span
                      style="
                        padding-left: 32px;
                        font-weight: 450;
                        font-size: 13px;
                      "
                      >Poin</span
                    >
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  elevation="0"
                  class="secondary--text"
                  style="background: #31d8a7; margin: 0px; padding: 0px"
                >
                  <v-list>
                    <v-list-item
                      v-for="item in itemsPoin"
                      :key="item.title"
                      link
                      :to="item.link"
                    >
                      <v-list-item-icon>
                        <v-icon class="secondary--text">{{ item.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="secondary--text">{{
                          item.title
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel v-show="showPanel(pnlSetupRole)">
                <v-expansion-panel-header
                  class="secondary--text"
                  style="background: #31d8a7; margin: 0px; padding: 0px"
                >
                  <div>
                    <v-icon>mdi-cog</v-icon>
                    <span
                      style="
                        padding-left: 32px;
                        font-weight: 450;
                        font-size: 13px;
                      "
                      >Setup</span
                    >
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  elevation="0"
                  class="secondary--text"
                  style="background: #31d8a7; margin: 0px; padding: 0px"
                >
                  <v-list>
                    <v-list-item
                      v-for="item in itemsSetup"
                      :key="item.title"
                      link
                      :to="item.link"
                    >
                      <v-list-item-icon>
                        <v-icon class="secondary--text">{{ item.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="secondary--text">{{
                          item.title
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-list-item>
          <v-list-item key="GantiPassword" link to="/ganti-password">
            <v-list-item-icon>
              <v-icon class="secondary--text">mdi-account-key</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="secondary--text"
                >Ganti Password</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-app-bar app :dark="getColorProd()" color="appBarColor">
        <v-app-bar-nav-icon
          :key="componentKey"
          @click.stop="menu"
        ></v-app-bar-nav-icon>

        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="@/assets/main-logo.png"
          transition="scale-transition"
          width="120"
        />
        <v-flex title font-weight-bold class="appBarText--text">
          Member Administrator
        </v-flex>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="logout" v-show="$session.exists()"
          ><v-icon>mdi-logout</v-icon>Logout
        </v-btn>
        <v-btn color="secondary" link to="/login" v-show="!$session.exists()"
          ><v-icon>mdi-login</v-icon>Login
        </v-btn>
      </v-app-bar>
    </nav>
    <v-main>
      <v-dialog v-model="$store.state.progress" persistent width="300">
        <v-card color="primary" dark pa-1>
          <v-card-text class="secondary--text">
            {{ $store.state.progressText }}
            <v-progress-linear
              indeterminate
              color="secondary"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-snackbar
        v-model="$store.state.snackbar"
        :color="$store.state.snackbarColor"
        :timeout="6000"
        top
        shaped
      >
        {{ $store.state.snackbarMessage }}
        <v-btn @click="$store.commit('closeSnackbar')">Close</v-btn>
      </v-snackbar>
      <v-container class="mt-8">
        <main class="mt-8">
          <router-view> </router-view>
        </main>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import firebase from "firebase";
require("firebase/auth");
import Properties from "./properties";

export default {
  name: "App",

  components: {},

  data: () => ({
    links: [
      {
        text: "Member",
        route: "/member",
      },
    ],
    componentKey: 0,
    showMenu: false,
    showNav: false,
    sessionRole: "",
    pnlPenukaranRole: "Kasir",
    pnlPromoRole: "Admin",
    pnlPoinRole: "Admin",
    pnlSetupRole: "SuperAdmin",

    itemsPromo: [
      {
        title: "Promo",
        icon: "mdi-gift",
        link: "/promo",
        isSuperAdmin: true,
        isAdmin: true,
        isKasir: false,
      },
      {
        title: "Promo Khusus",
        icon: "mdi-gift-outline",
        link: "/promo-khusus",
        isSuperAdmin: true,
        isAdmin: true,
        isKasir: false,
      },
      {
        title: "Notifikasi",
        icon: "mdi-bell-ring",
        link: "/notifikasi",
        isSuperAdmin: true,
        isAdmin: true,
        isKasir: false,
      },
    ],
    itemsPoin: [
      {
        title: "Poin ke Gift",
        icon: "mdi-diamond-stone",
        link: "/setup-tukar-gift",
        isSuperAdmin: true,
        isAdmin: true,
        isKasir: false,
      },
      {
        title: "Poin ke Voucher",
        icon: "mdi-ticket-percent",
        link: "/setup-tukar-voucher",
        isSuperAdmin: true,
        isAdmin: true,
        isKasir: false,
      },
      {
        title: "Poin Manual",
        icon: "mdi-ticket-percent",
        link: "/setup-tukar-voucher-admin",
        isSuperAdmin: true,
        isAdmin: true,
        isKasir: false,
      },
    ],
    itemsPenukaran: [
      // {
      //   title: "Tukar Promo",
      //   icon: "mdi-tag-arrow-right",
      //   link: "/tukar-promo",
      //   isSuperAdmin: true,
      //   isAdmin: true,
      //   isKasir: true,
      // },
      {
        title: "Ambil Gift",
        icon: "mdi-database-arrow-right",
        link: "/ambil-gift",
        isSuperAdmin: true,
        isAdmin: true,
        isKasir: true,
      },
    ],
    itemsLaporan: [
      {
        title: "Laporan Tukar Promo",
        icon: "mdi-file-document",
        link: "/laporan-tukar-promo",
        isSuperAdmin: true,
        isAdmin: true,
        isKasir: true,
      },
      {
        title: "Rekap Merchant",
        icon: "mdi-file-document",
        link: "/rekap-tukar-promo-merchant",
        isSuperAdmin: true,
        isAdmin: true,
        isKasir: true,
      },
      {
        title: "Merchant Akan Habis Kontrak",
        icon: "mdi-file-document",
        link: "/merchant-habis",
        isSuperAdmin: true,
        isAdmin: true,
        isKasir: true,
      },
      {
        title: "Laporan Merchant",
        icon: "mdi-file-document",
        link: "/laporan-tukar-promo-merchant",
        isSuperAdmin: true,
        isAdmin: true,
        isKasir: true,
      },
      {
        title: "Laporan Poin Gift",
        icon: "mdi-file-document",
        link: "/laporan-tukar-poin-gift",
        isSuperAdmin: true,
        isAdmin: true,
        isKasir: true,
      },
      {
        title: "Laporan Poin Voucher",
        icon: "mdi-file-document",
        link: "/laporan-tukar-poin-voucher",
        isSuperAdmin: true,
        isAdmin: true,
        isKasir: true,
      },
    ],
    itemsSetup: [
      {
        title: "Setup Poin",
        icon: "mdi-star-cog",
        link: "/setup-poin",
        isSuperAdmin: true,
        isAdmin: false,
        isKasir: false,
      },
      {
        title: "Setup Kategori Promo",
        icon: "mdi-shape",
        link: "/setup-kategori-promo",
        isSuperAdmin: true,
        isAdmin: false,
        isKasir: false,
      },
      {
        title: "Setup Merchant",
        icon: "mdi-basket",
        link: "/setup-merchant",
        isSuperAdmin: true,
        isAdmin: false,
        isKasir: false,
      },
      {
        title: "Setup Toko",
        icon: "mdi-store",
        link: "/setup-toko",
        isSuperAdmin: true,
        isAdmin: false,
        isKasir: false,
      },
      {
        title: "Setup Admin",
        icon: "mdi-account-supervisor-circle",
        link: "/setup-admin",
        isSuperAdmin: true,
        isAdmin: false,
        isKasir: false,
      },
    ],
    items: [],

    created() {
      this.initialized();
    },
  }),
  computed: {
    isSuperAdmin() {
      return this.sessionRole === "SuperAdmin";
    },
    isAdmin() {
      return this.sessionRole === "Admin";
    },
    isKasir() {
      return this.sessionRole === "Kasir";
    },
  },
  methods: {
    getColorProd() {
      return Properties.BUILD_TYPE == "production" ? false : true;
    },
    showItemMenu() {
      return false;
    },
    showPanel(pnlRole) {
      if (pnlRole === "Kasir") {
        return true;
      } else if (pnlRole === "Admin") {
        if (this.sessionRole === "Kasir") return false;
        else return true;
      } else if (pnlRole === "SuperAdmin") {
        if (this.sessionRole === "SuperAdmin") return true;
        else return false;
      } else return false;
    },
    async initialized() {
      if (await firebase.auth().currentUser) {
        this.showMenu = true;
        if (
          this.$session.get("role") != null ||
          this.$session.get("role") != undefined ||
          this.$session.get("role") != ""
        ) {
          this.sessionRole = this.$session.get("role");
        }
      } else {
        this.showMenu = false;
      }
      if (this.sessionRole == "Kasir") {
        this.items = this.itemsKasir;
      } else {
        this.items = this.itemsAdmin;
      }
    },
    async logout() {
      this.$store.commit("logout");
    },
    menu() {
      this.initialized();
      this.showNav = true;
    },
  },
};
</script>
<style scoped>
.v-expansion-panel::before {
  box-shadow: none;
}
</style>
