<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="vouchers"
      hide-default-footer
      class="elevation-1"
      :items-per-page="itemsPerPage"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title class="mr-2">VOUCHER POIN SETUP</v-toolbar-title>
          <v-dialog v-model="dialog" max-width="1000px" persistent>
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark class="mb-2" v-on="on" small
                ><v-icon class="white--text">mdi-plus</v-icon></v-btn
              >
            </template>
            <v-card v-show="!formStock">
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text ref="form">
                <v-container>
                  <v-text-field
                    ref="judul"
                    v-model="editedItem.judul"
                    label="Judul"
                    :rules="[rules.judul]"
                    :disabled="editMode"
                  ></v-text-field>
                  <p class="mt-5 mb-1 subtitle-1">Keterangan</p>
                  <v-sheet elevation="6">
                    <ckeditor
                      :editor="editor"
                      v-model="editedItem.keterangan"
                      :config="editorConfig"
                      :disabled="editMode"
                    ></ckeditor>
                  </v-sheet>
                  <v-row>
                    <v-col>
                      <v-text-field
                        ref="minimal_belanja"
                        v-model="editedItem.minimal_belanja"
                        min="0"
                        prefix="Rp."
                        suffix=",00"
                        label="Minimal Belanja"
                        :rules="[rules.minimalBelanja]"
                        :disabled="editMode"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        ref="nominal"
                        v-model="editedItem.nominal"
                        min="0"
                        prefix="Rp. "
                        suffix=",00"
                        label="Nominal"
                        :rules="[rules.nominal_null, rules.nominal]"
                        :disabled="editMode"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        ref="kadaluwarsa"
                        v-model="editedItem.kadaluwarsa"
                        min="0"
                        label="Kadaluwarsa"
                        :rules="[rules.kadaluwarsa_null, rules.kadaluwarsa]"
                        :disabled="editMode"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        ref="jml_per_pelanggan"
                        v-model="editedItem.jml_per_pelanggan"
                        min="0"
                        label="Jumlah Per Pelanggan"
                        :rules="[
                          rules.jml_per_pelanggan_null,
                          rules.jml_per_pelanggan
                        ]"
                        :disabled="editMode"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-file-input
                      ref="csv"
                      v-model="editedItem.ls_pelanggan"
                      @change="onAddCSV($event)"
                      accept=".csv"
                      label="File CSV Pelanggan"
                      prepend-icon="mdi-file"
                      :disabled="editMode"
                    ></v-file-input>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6" sm="6">
                      <v-file-input
                        ref="foto_thumbnail"
                        v-model="editedItem.ft"
                        :rules="fotoThumbnailValidator"
                        @change="onAddFilesThumbnail"
                        accept="image/png, image/jpeg, image/bmp, image/jpg"
                        label="Foto Landscape 16:9"
                        prepend-icon="mdi-camera"
                        :disabled="editMode"
                      ></v-file-input>
                    </v-col>

                    <v-col cols="12" md="6" sm="6">
                      <v-file-input
                        ref="foto"
                        v-model="editedItem.ft_thumbnail"
                        :rules="fotoValidator"
                        @change="onAddFiles"
                        accept="image/png, image/jpeg, image/bmp, image/jpg"
                        label="Foto Landscape 16:9"
                        prepend-icon="mdi-camera"
                        :disabled="editMode"
                      ></v-file-input>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <span class="title">Foto Landscape 16:9</span>
                      <v-img
                        :key="keyThumbnail"
                        :src="thumbnailURL"
                        :v-if="showThumbnail"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="primary"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>

                    <v-col cols="12" md="6">
                      <span class="title">Foto Landscape 16:9</span>
                      <v-img :key="keyFoto" :src="fotoURL" v-if="showFoto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="primary"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="editMode" color="textGrey" text @click="close"
                  >Tutup</v-btn
                >
                <v-btn v-if="!editMode" color="textGrey" text @click="close"
                  >Batal</v-btn
                >
                <v-btn v-if="!editMode" color="accent" text @click="save"
                  >Simpan</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-divider vertical light class="mx-2"></v-divider>
          <v-row align="center" width="500px">
            <v-col cols="12" md="3">
              <v-text-field
                v-model="searchText"
                label="Judul Voucher"
                hide-details
                clearable="true"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="2">
              <v-text-field
                v-model="filterTahun"
                label="Tahun"
                hide-details
                clearable="true"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="1">
              <v-btn color="primary" @click="praSearch"
                ><v-icon>mdi-magnify</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          v-if="item.status_kirim"
          small
          @click="detailItem(item)"
          width="70"
          color="success"
          class="mr-1"
        >
          <v-icon small>mdi-file</v-icon> Detail
        </v-btn>
        <v-btn
          v-if="!item.status_kirim"
          small
          @click="kirimItem(item)"
          width="70"
          color="info"
          class="mr-1"
        >
          <v-icon small>mdi-send</v-icon> Kirim
        </v-btn>
        <v-btn
          v-if="!item.status_kirim"
          small
          @click="editItem(item)"
          width="70"
          color="warn"
          class="mr-1"
        >
          <v-icon small>mdi-pencil</v-icon> Edit
        </v-btn>
        <v-btn
          v-if="!item.status_kirim"
          small
          @click="deleteItem(item)"
          width="70"
          color="error"
          class="mr-1"
        >
          <v-icon small>mdi-trash</v-icon> Delete
        </v-btn>
      </template>
      <template v-slot:no-data>
        Belum ada data voucher...
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
        @input="search"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import VoucherManualAPIServices from "../../services/VoucherPoinManualAPIServices";
import firebase from "firebase";
require("firebase/auth");

import ClassicEditor from "@ckeditor/ckeditor5-build-inline";
import { mapMutations } from "vuex";

export default {
  name: "SetupTukarVoucher",

  data: () => ({
    rules: {
      judul: value => !!value || "Masukkan Judul.",
      nominal_null: value => !!value || "Masukkan Nominal.",
      nominal: value => {
        const pattern = /^[0-9]*$/;
        return pattern.test(value) || "Nominal hanya mengandung angka";
      },
      jml_per_pelanggan_null: value => !!value || "Masukkan Harga Poin.",
      jml_per_pelanggan: value => {
        const pattern = /^[0-9]*$/;
        return pattern.test(value) || "Harga poin hanya mengandung angka";
      },
      kadaluwarsa_null: value =>
        !!value || "Masukkan masa kadaluwarsa voucher.",
      kadaluwarsa: value => {
        const pattern = /^[0-9]*$/;
        return pattern.test(value) || "Nomor identitas hanya mengandung angka";
      },
      periodeAwal: value => !!value || "Masukkan Periode Awal.",
      periodeAkhir: value => !!value || "Masukkan Periode Akhir.",
      minimalBelanja: value => {
        const pattern = /^[0-9]*$/;
        return pattern.test(value) || "Minimal belanja hanya mengandung angka";
      }
    },

    editor: ClassicEditor,
    editorConfig: {
      toolbar: [
        "heading",
        "|",
        "bold",
        "italic",
        "bulletedList",
        "numberedList",
        "blockQuote",
        "|",
        "indent",
        "outdent",
        "|",
        "undo",
        "redo"
      ]
    },

    editMode: false,
    sessionNIK: "",
    token: "",

    tempFoto: null,
    tempThumbnail: null,
    showFoto: false,
    showThumbnail: false,
    fotoURL: "",
    thumbnailURL: "",
    keyFoto: 0,
    keyThumbnail: 0,
    dialog: false,
    dialogCode: "",

    headers: [
      {
        text: "Judul",
        align: "start",
        value: "judul",
        sortable: false
      },
      {
        text: "Nominal",
        align: "start",
        value: "nominal",
        sortable: false
      },
      { text: "Minimal Belaja", value: "minimal_belanja", sortable: false },
      { text: "Nominal", value: "nominal", sortable: false },
      { text: "Kadaluwarsa", value: "kadaluwarsa", sortable: false },
      { text: "Action", value: "actions", sortable: false }
    ],
    vouchers: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      jenis: "",
      judul: "",
      keterangan: "",
      minimal_belanja: "",
      nominal: "",
      foto: "",
      foto_thumbnail: "",
      periode_awal: "",
      periode_akhir: "",
      maksimal_waktu_tukar: "",
      jml_per_pelanggan: "",
      list_pelanggan: "",
      stock: "",
      harga_poin: 0
    },
    defaultItem: {
      id: "",
      jenis: "",
      judul: "",
      keterangan: "",
      minimal_belanja: "",
      nominal: "",
      foto: "",
      foto_thumbnail: "",
      periode_awal: "",
      periode_akhir: "",
      maksimal_waktu_tukar: "",
      jml_per_pelanggan: "",
      list_pelanggan: "",
      stock: "",
      harga_poin: 0
    },

    page: 1,
    pageCount: 1,
    itemsPerPage: 10
  }),

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  created() {
    this.initialize();
  },

  computed: {
    fotoValidator() {
      return [
        (this.tempFoto != null && this.tempFoto.size < 1000000) ||
          "Foto tidak boleh kosong, ukuran max 1 MB"
      ];
    },
    fotoThumbnailValidator() {
      return [
        (this.tempThumbnail != null && this.tempThumbnail.size < 1000000) ||
          "Foto tidak boleh kosong, ukuran max 1 MB"
      ];
    },

    formTitle() {
      return this.editedIndex === -1 ? "Tambah Voucher" : this.editedItem.status_kirim === 1 ? "Detail Voucher" : "Edit Voucher";
    },

    formStock() {
      return this.dialogCode === "tambahStock" ? true : false;
    },

    formEdit() {
      return {
        judul: this.editedItem.judul,
        nominal: this.editedItem.nominal,
        periodeAwal: this.editedItem.periode_awal,
        periodeAkhir: this.editedItem.periode_akhir,
        kadaluwarsa: this.editedItem.kadaluwarsa,
        harga_poin: this.editedItem.harga_poin
      };
    },

    formTambah() {
      return {
        judul: this.editedItem.judul,
        foto: this.tempFoto,
        foto_thumbnail: this.tempThumbnail,
        nominal: this.editedItem.nominal,
        periodeAwal: this.editedItem.periode_awal,
        periodeAkhir: this.editedItem.periode_akhir,
        kadaluwarsa: this.editedItem.kadaluwarsa,
        harga_poin: this.editedItem.harga_poin
      };
    },

    validate() {
      var formHasErrors = false;

      if (this.formTitle == "Tambah Voucher")
        Object.keys(this.formTambah).forEach(f => {
          if (!this.formTambah[f]) formHasErrors = true;

          this.$refs[f].validate(true);
        });
      else
        Object.keys(this.formEdit).forEach(f => {
          if (!this.formEdit[f]) formHasErrors = true;

          this.$refs[f].validate(true);
        });

      return !formHasErrors;
    }
  },

  methods: {
    async initialize() {
      if (this.$session.exists()) {
        try {
          await firebase
            .auth()
            .currentUser.getIdTokenResult()
            .then(token => {
              this.token = token.token;
              this.sessionNIK = this.$session.get("nik");
            });
        } catch (e) {
          this.$store.commit("logout");
        }
      } else {
        this.$store.commit("logout");
      }
      this.praSearch();
    },

    onAddCSV(e) {
      this.editedItem.list_pelanggan = []
      let lines = ""
      let currentLine = ""
      let csv = ""
      let headers = ""
      let result = []
      let readers = new FileReader();
      readers.readAsBinaryString(e);
      readers.onload = () => {
        csv = readers.result;
        lines = csv.split('\r' + '\n');
        headers = lines[0].split(",");

        for(var i = 1; i < lines.length; i++){
          if(!lines[i]){
            continue
          }
          let obj = {}
          currentLine = lines[i];
          var re = /"/g;
          currentLine = re[Symbol.replace](currentLine, '');
          currentLine = currentLine.split(",");

          for(var j = 0; j < headers.length; j++){
            if(j==0||j==1||j==2||j==3||j==4){
              let head = headers[j].trim();
              let value = currentLine[j].trim();
              obj[head] = value
            }
          }
          // console.log(obj)
          result.push(obj);
        }
        this.editedItem.list_pelanggan = JSON.stringify(result);
        console.log(this.editedItem.list_pelanggan);
      }
    },

    onAddFiles(e) {
      this.tempFoto = e;
      if (e != null) {
        const fr = new FileReader();

        fr.readAsDataURL(e);

        fr.addEventListener("load", () => {
          this.fotoURL = fr.result;
          this.showFoto = true;
          this.editedItem.foto = this.tempFoto;
        });
      } else {
        if (this.editedItem.foto != "") {
          this.fotoURL = "http://" + this.editedItem.foto;
          this.showFoto = true;
        } else {
          this.fotoURL = "";
          this.showFoto = false;
          this.tempFoto = null;
        }
      }
    },

    onAddFilesThumbnail(e) {
      this.tempThumbnail = e;
      if (e != null) {
        const fr = new FileReader();

        fr.readAsDataURL(e);

        fr.addEventListener("load", () => {
          this.thumbnailURL = fr.result;
          this.showThumbnail = true;
          this.editedItem.foto_thumbnail = this.tempThumbnail;
        });
      } else {
        if (this.editedItem.foto_thumbnail != "") {
          this.thumbnailURL = "http://" + this.editedItem.foto_thumbnail;
          this.showThumbnail = true;
        } else {
          this.thumbnailURL = "";
          this.showThumbnail = false;
          this.tempThumbnail = null;
        }
      }
    },

    praSearch() {
      this.page = 1;
      this.search();
    },

    search() {
      this.admins = [];
      this.$store.commit("showProgress", {
        message: "Mengambil data voucher"
      });
      var body = {};
      body.judul = this.searchText;
      body.tahun = this.filterTahun;
      body.limit = this.itemsPerPage;
      VoucherManualAPIServices.filter(this.token, body, this.page)
        .then(response => {
          this.page = response.data.current_page;
          this.pageCount = response.data.last_page;
          this.vouchers = response.data.data;
        })
        .catch(error => {
          if (error.response) {
            if (error.response.data.message) {
              this.showSnackbar({
                color: "error",
                message: error.response.data.message
              });
            }
          } else if (error.request) {
            this.showSnackbar({
              color: "error",
              message: "Koneksi ke server gagal (ERROR)"
            });
          } else {
            this.showSnackbar({
              color: "error",
              message: "Voucher gagal dimuat."
            });
          }
        })
        .finally(() => {
          this.$store.commit("closeProgress");
        });
    },

    getKaryawan() {},

    kirimItem(item) {
      this.$store.commit("showProgress", {
        message: "Proses mengirim voucher",
      });
      VoucherManualAPIServices.kirim(this.token, item.id, this.sessionNIK)
        .then(() => {
          this.dialog = false;
          this.showSnackbar({
            color: "secondary",
            message: "Voucher berhasil di kirim",
          });
          this.search();
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message) {
              this.showSnackbar({
                color: "error",
                message: error.response.data.message,
              });
            }
          } else if (error.request) {
            this.showSnackbar({
              color: "error",
              message: "Koneksi ke server gagal (ERROR)",
            });
          } else {
            this.showSnackbar({
              color: "error",
              message: "Voucher gagal di kirim",
            });
          }
        })
        .finally(() => {
          this.$store.commit("closeProgress");
        });
    },

    editItem(item) {
      this.editedIndex = this.vouchers.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.fotoURL = "http://" + item.foto;
      this.thumbnailURL = "http://" + item.foto_thumbnail;
      this.showFoto = true;
      this.showThumbnail = true;
      this.editMode = false;
      this.dialog = true;
    },

    detailItem(item) {
      this.editedIndex = this.vouchers.indexOf(item);
      this.editedItem = Object.assign({}, item);

      this.fotoURL = "http://" + item.foto;
      this.thumbnailURL = "http://" + item.foto_thumbnail;
      this.showFoto = true;
      this.showThumbnail = true;
      this.editMode = true;
      this.dialog = true;
    },

    tambahStockDialog(item) {
      this.dialogCode = "tambahStock";
      this.editedIndex = this.vouchers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      confirm("Hapus " + item.judul + "?") &&
        (this.$store.commit("showProgress", {
          message: "Proses menghapus voucher"
        }),
        VoucherManualAPIServices.delete(this.token, item.id, this.sessionNIK)
          .then(() => {
            this.showSnackbar({
              color: "secondary",
              message: "Voucher berhasil dihapus"
            });
            this.initialize();
          })
          .catch(error => {
            if (error.response) {
              if (error.response.data.message) {
                this.showSnackbar({
                  color: "error",
                  message: error.response.data.message
                });
              }
            } else if (error.request) {
              this.showSnackbar({
                color: "error",
                message: "Koneksi ke server gagal (ERROR)"
              });
            } else {
              this.showSnackbar({
                color: "error",
                message: "Voucher gagal dihapus"
              });
            }
          })
          .finally(() => {
            this.$store.commit("closeProgress");
          }));
    },

    close() {
      this.dialog = false;
      this.editMode = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.keyFoto++;
        this.keyThumbnail++;
        this.showFoto = false;
        this.showThumbnail = false;
        this.editedItem.ft = null;
        this.editedItem.ft_thumbnail = null;
        this.editedItem.ls_pelanggan = null;

        this.onAddFiles(null);
        this.onAddFilesThumbnail(null);

        Object.keys(this.formTambah).forEach(f => {
          this.$refs[f].resetValidation();
        });
      }, 300);
    },

    save() {
      // if (this.validate) {
        if (this.formTitle == "Tambah Voucher") {
          this.$store.commit("showProgress", {
            message: "Proses menambah voucher"
          });
          this.editedItem.user_create = this.sessionNIK;
          let formData = new FormData();
          if (this.editedItem.foto != null)
            formData.append("foto", this.editedItem.foto);
          if (this.editedItem.foto_thumbnail != null)
            formData.append("foto_thumbnail", this.editedItem.foto_thumbnail);
          formData.append("minimal_belanja", this.editedItem.minimal_belanja);
          formData.append("harga_poin", this.editedItem.harga_poin);
          formData.append("judul", this.editedItem.judul);
          formData.append("kadaluwarsa", this.editedItem.kadaluwarsa);
          formData.append("keterangan", this.editedItem.keterangan);
          formData.append("nominal", this.editedItem.nominal);
          formData.append("periode_awal", this.editedItem.periode_awal);
          formData.append("periode_akhir", this.editedItem.periode_akhir);
          formData.append("jml_per_pelanggan", this.editedItem.jml_per_pelanggan);
          formData.append("list_pelanggan", this.editedItem.list_pelanggan);
          formData.append("user_create", this.sessionNIK);
          // Display the key/value pairs
          for (var pair of formData.entries()) {
              console.log(pair[0]+ ', ' + pair[1]); 
          }
          VoucherManualAPIServices.create(this.token, formData)
            .then(() => {
              this.close();
              this.showSnackbar({
                color: "secondary",
                message: "Data berhasil di tambah"
              });
            })
            .catch(error => {
              if (error.response) {
                if (error.response.data.message) {
                  this.showSnackbar({
                    color: "error",
                    message: error.response.data.message
                  });
                }
              } else if (error.request) {
                this.showSnackbar({
                  color: "error",
                  message: "Koneksi ke server gagal (ERROR)"
                });
              } else {
                this.showSnackbar({
                  color: "error",
                  message: "Voucher gagal di tambah"
                });
              }
            })
            .finally(() => {
              this.$store.commit("closeProgress");
              this.initialize();
            });
        } else {
          this.$store.commit("showProgress", {
            message: "Proses mengedit voucher"
          });
          this.editedItem.user_update = this.sessionNIK;
          let formData = new FormData();
          if (this.editedItem.foto != null)
            formData.append("foto", this.editedItem.foto);
          if (this.editedItem.foto_thumbnail != null)
            formData.append("foto_thumbnail", this.editedItem.foto_thumbnail);
          formData.append("minimal_belanja", this.editedItem.minimal_belanja);
          formData.append("harga_poin", this.editedItem.harga_poin);
          formData.append("judul", this.editedItem.judul);
          formData.append("kadaluwarsa", this.editedItem.kadaluwarsa);
          formData.append("keterangan", this.editedItem.keterangan);
          formData.append("nominal", this.editedItem.nominal);
          formData.append("periode_awal", this.editedItem.periode_awal);
          formData.append("periode_akhir", this.editedItem.periode_akhir);
          formData.append("jml_per_pelanggan", this.editedItem.jml_per_pelanggan);
          formData.append("list_pelanggan", this.editedItem.list_pelanggan);
          formData.append("status", this.editedItem.status);
          formData.append("user_update", this.sessionNIK);
          VoucherManualAPIServices.edit(this.token, this.editedItem.id, formData)
            .then(() => {
              this.close();
              this.showSnackbar({
                color: "secondary",
                message: "Data berhasil di edit"
              });
              this.initialize();
            })
            .catch(error => {
              if (error.response) {
                if (error.response.data.message) {
                  this.showSnackbar({
                    color: "error",
                    message: error.response.data.message
                  });
                }
              } else if (error.request) {
                this.showSnackbar({
                  color: "error",
                  message: "Koneksi ke server gagal (ERROR)"
                });
              } else {
                this.showSnackbar({
                  color: "error",
                  message: "Voucher gagal di edit"
                });
              }
              this.snackbar = true;
              this.snackbarColor = "error";
            })
            .finally(() => {
              this.$store.commit("closeProgress");
              this.initialize();
            });
        }
      // } else {
      //   this.showSnackbar({
      //     color: "error",
      //     message: "Input tidak valid"
      //   });
      // }
    },

    ...mapMutations(["showSnackbar"])
  }
};
</script>
