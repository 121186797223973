import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import VueSession from "vue-session";
import router from "./router";
import store from "./store";
import axios from "axios";
import firebase from 'firebase';
require('firebase/auth');
import CKEditor from "@ckeditor/ckeditor5-vue";
import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBXFBbNPZ6p2CzkY50oha-v_Cxz6jDa-KM",
    libraries: "places", // necessary for places input
  },
});

Vue.use(require('vue-moment'));
Vue.use(VueSession, { persist: true });
Vue.use(CKEditor);

Vue.prototype.$axios = axios;
Vue.config.productionTip = false;
Vue.config.silent = true;

const firebaseConfig = {
  // apiKey: "AIzaSyBJawOaSkBcY1gKIo9M1so1u4g4E2vtZZc",
  // authDomain: "amigo-test-ed578.firebaseapp.com",
  // databaseURL: "https://amigo-test-ed578.firebaseio.com",
  // projectId: "amigo-test-ed578",
  // storageBucket: "amigo-test-ed578.appspot.com",
  // messagingSenderId: "3185370502",
  // appId: "1:3185370502:web:20a43bd49646761c51f807"

  apiKey: "AIzaSyC1_wEUIiYMVs3l8FzY1ssj5pSRT3TkQJQ",
  authDomain: "amigo-member-apps.firebaseapp.com",
  databaseURL: "https://amigo-member-apps.firebaseio.com",
  projectId: "amigo-member-apps",
  storageBucket: "amigo-member-apps.appspot.com",
  messagingSenderId: "741031502844",
  appId: "1:741031502844:web:af8f7b393708291cc04879",
};
firebase.initializeApp(firebaseConfig);

let app;

firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      vuetify,
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
